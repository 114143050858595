import React from "react"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import richText from "../utils/richText"
import { Body, BodyLarge, Header2, Header3 } from "../components/typography"
import colors from "../styles/colors"
import styled from "styled-components"
import Button from "../components/common/PrimaryButton"
import Seo from "../components/seo"

const Header = styled.div`
  color: ${colors.creamLight};

  margin: 0 auto;

  padding: 48px 24px;

  border-bottom: 1px solid ${colors.creamLight};

  text-align: center;
`

const Wrapper = styled.div`
  color: ${colors.creamLight};
  background-position: bottom;
  background-size: cover;

  padding: 24px 48px;

  display: flex;
  justify-content: center;

  min-height: 70vh;
  align-items: center;

  > div {
    text-align: center;

    ${BodyLarge} {
      margin-bottom: 24px;
    }

    a {
      margin-top: 40px;
    }
  }
`
export function Head() {
  return <Seo title={"Thank you"} />
}

export default function OfferPage() {
  return (
    <Layout>
      <Wrapper>
        <div>
          <BodyLarge>Thanks!</BodyLarge>
          <Body>We've sent your offer code to your email!</Body>
          <Button as={Link} to="/shop">
            Shop
          </Button>
        </div>
      </Wrapper>
    </Layout>
  )
}
